/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}/* HTML5 display-role reset for older browsers */article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
  display: block;
}

body {
  line-height: 1;
}

ol,ul {
  list-style: none;
}

blockquote,q {
  quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.contain {
  display: block;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
}

*,*::before,*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  background-color: #f6f5f3;
}

html[data-focus-source="key"] :focus {
  z-index: 90;
  outline-offset: 6px;
  outline: 4px solid #ebc71b;
}

html :focus {
  outline: 0;
}

.main_content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.page_content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
}

.page_content__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.page_content__inner > *:first-child {
  margin-top: 2.427rem;
}

.page_content__inner > *:last-child {
  margin-bottom: 2.427rem;
}

.home {
  background-color: #f2faf9;
}

img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}

* + img {
  margin-top: 1.618rem;
}

svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}

button {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}

input {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
}

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --ratio: 1.1;
  --fz--h1: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) *  var(--ratio));
  --fz--h2: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz--h3: calc(1rem * var(--ratio) * var(--ratio));
  --fz--h4: calc(1rem * var(--ratio));
  --fz--sub: calc(1rem / var(--ratio));
  --fz--supersub: calc((1rem / var(--ratio)) / var(--ratio));
  --sp--h1: calc(var(--fz--h1) * 1.5);
  --sp--h2: calc(var(--fz--h2) * 1.5);
  --sp--h3: calc(var(--fz--h3) * 1.5);
  --sp--h4: calc(var(--fz--h4) * 1.5);
}

body,select,input,textarea {
  font-family: 'relative--book', sans-serif;
  font-weight: normal;
  line-height: 1.618;
  font-size: inherit;
  color: hsla(200,19%,27%,1);
}

h1,h2,h3,h4 {
  display: block;
  line-height: 1.5;
  font-family: 'relative--medium', sans-serif;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: hsla(200,29%,21%,1);
}

h1 a,h2 a,h3 a,h4 a,h1 a:visited,h2 a:visited,h3 a:visited,h4 a:visited {
  color: hsla(199,55%,30%,1);
}

h1 a:active,h2 a:active,h3 a:active,h4 a:active,h1 a:visited:active,h2 a:visited:active,h3 a:visited:active,h4 a:visited:active,h1 a:hover,h2 a:hover,h3 a:hover,h4 a:hover,h1 a:visited:hover,h2 a:visited:hover,h3 a:visited:hover,h4 a:visited:hover,h1 a:focus,h2 a:focus,h3 a:focus,h4 a:focus,h1 a:visited:focus,h2 a:visited:focus,h3 a:visited:focus,h4 a:visited:focus {
  color: #1b3846;
}

h1 {
  max-width: 37ch;
  font-size: 1.4641rem;
  font-size: var(--fz--h1);
}

h2 {
  max-width: 37ch;
  font-size: 1.331rem;
  font-size: var(--fz--h2);
}

* + h2 {
  margin-top: 1.996500000000001rem;
  margin-top: var(--sp--h2);
}

h3 {
  max-width: 51.8ch;
  font-size: var(--fz--h3);
}

* + h3 {
  margin-top: 1.815rem;
  margin-top: var(--sp--h3);
}

h4 {
  max-width: 64ch;
  font-size: var(--fz--h4);
}

* + h4 {
  margin-top: 1.65rem;
  margin-top: var(--sp--h4);
}

p {
  width: auto;
  max-width: 100%;
  font-family: 'relative--book', sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: -0.01em;
  color: hsla(200,19%,27%,1);
}

.reading_content p,p.reading_content {
  width: 74ch;
}

.reading_content p > a,p.reading_content > a,.reading_content p > a:visited,p.reading_content > a:visited {
  color: hsla(199,55%,30%,1);
}

.reading_content p > a:active,p.reading_content > a:active,.reading_content p > a:visited:active,p.reading_content > a:visited:active,.reading_content p > a:hover,p.reading_content > a:hover,.reading_content p > a:visited:hover,p.reading_content > a:visited:hover,.reading_content p > a:focus,p.reading_content > a:focus,.reading_content p > a:visited:focus,p.reading_content > a:visited:focus {
  color: #1b3846;
}

h1 + p,h2 + p,h3 + p {
  margin-top: 0.539333333333333rem;
}

h4 + p {
  margin-top: 0.809rem;
}

* + p {
  margin-top: 1.618rem;
}

a,a:visited {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

b,strong {
  font-weight: normal;
  font-family: 'relative--medium', sans-serif;
}

i,em {
  font-style: normal;
  font-family: 'relative--book', sans-serif;
}

::-moz-selection {
  background-color: #33282c;
  color: #fff;
}

::selection {
  background-color: #33282c;
  color: #fff;
}

footer ::-moz-selection {
  background-color: hsla(338,1%,7%,1);
}

footer ::selection {
  background-color: hsla(338,1%,7%,1);
}

.reading_content__list {
  display: block;
  width: 70ch;
  max-width: 100%;
  padding-left: 3ch;
}

* + .reading_content__list {
  margin-top: 1.618rem;
}

ol.reading_content__list {
  list-style-type: decimal;
}

ul.reading_content__list {
  list-style-type: disc;
}

.reading_content__list li {
  max-width: 100%;
}

.reading_content__list li + li {
  margin-top: 0.809rem;
}

sup {
  vertical-align: super;
  font-size: 0.909090909090909rem;
  font-size: var(--fz--sub);
}

.global_footer {
  width: 100%;
  background-color: #0072bc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 48px 16px;
}

.global_footer__inner {
  width: 100%;
  max-width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.global_footer__inner__logo {
  height: 40px;
  width: auto;
}

.global_footer__inner__main_section {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.global_footer__navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 12px 0;
}

.global_footer__navigation__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 8px 0;
  color: #fff;
}

.global_footer__navigation__item a {
  color: #fff;
  white-space: nowrap;
}

.global_footer__navigation__social_media_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.global_footer__navigation__social_media_list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 24px;
}

.global_footer__navigation__social_media_list__item a {
  width: 24px;
  height: 24px;
}

.global_footer__copyright {
  color: #dceefd;
  text-align: left;
  margin-top: 20px;
}

.global_header__search__form {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: hsla(200,9%,33%,0.4);
}

.search_is_open .global_header__search__form {
  -webkit-animation: fade-in 0.2s;
  animation: fade-in 0.2s;
  display: block;
}

.global_header__search__form__inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  -webkit-box-shadow: 0 0.1em 0.3em hsla(200,39%,15%,0.4);
  box-shadow: 0 0.1em 0.3em hsla(200,39%,15%,0.4);
  margin: 0 auto;
  background-color: #33282c;
  padding: 3.236rem 4vw;
}

.search_is_open .global_header__search__form__inner {
  -webkit-animation: search-modal-slide-down 0.2s;
  animation: search-modal-slide-down 0.2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.global_header__search__form__close_button {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  height: 1.618rem;
  width: 1.618rem;
  -webkit-box-shadow: 0 1px 0.06rem rgba(23,43,53,0.4);
  box-shadow: 0 1px 0.06rem rgba(23,43,53,0.4);
  border-radius: 50%;
}

.global_header__search__form__close_button:active:active,.global_header__search__form__close_button:focus:active,.global_header__search__form__close_button:hover:active,.global_header__search__form__close_button:active:focus,.global_header__search__form__close_button:focus:focus,.global_header__search__form__close_button:hover:focus,.global_header__search__form__close_button:active:hover,.global_header__search__form__close_button:focus:hover,.global_header__search__form__close_button:hover:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.global_header__search__form__close_icon__circle {
  fill: hsla(338,23%,29%,1);
}

.global_header__search__form__close_icon__path {
  fill: #fff;
}

.global_header__search__form__text_input,.global_header__search__form__submit {
  -webkit-box-shadow: 0 1px 0.06rem rgba(23,43,53,0.4);
  box-shadow: 0 1px 0.06rem rgba(23,43,53,0.4);
  border-radius: 3px;
  border: 1px solid #fff;
  background-color: hsla(338,23%,29%,1);
  padding: 0.539333333333333rem 1.077588rem 0.4045rem;
  text-shadow: none;
  color: #fff;
}

* + .global_header__search__form__text_input,* + .global_header__search__form__submit {
  margin-left: 0.539333333333333rem;
}

.global_header__search__form__text_input {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  background-color: hsla(338,23%,29%,1);
  font-family: 'relative--book', sans-serif;
}

.global_header__search__form__submit {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.global_header {
  width: 100%;
  padding: 0;
}

.global_header__global_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.global_header__global_content__inner {
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 1394px;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(30,64,120,0.102);
  box-shadow: 0px 0px 14px 0px rgba(30,64,120,0.102);
  border: 0px, 0px, 1px, 0px;
  z-index: 20;
}

.global_header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.global_header__logo__inline_image {
  height: 40px;
  width: auto;
}

.global_header__navigation {
  display: none;
}

.global_header__navigation__anchor {
  padding: 12px;
  color: #1e4078;
}

.global_header__user--is_signed_in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.global_header__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
}

.global_header__user__anchor {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px;
}

.global_header__user__anchor__sign_in {
  color: #344054;
  border: 1px #b9e2fe solid;
  margin-right: 8px;
  white-space: nowrap;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
}

.global_header__user__anchor__register {
  color: #1e4078;
  border: 1px #51b0ad solid;
  background-color: #7acbc6;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
}

.global_header__user--is_signed_in .global_header__user__anchor {
  display: none;
}

.global_header__user__signed_in_message {
  display: none;
}

.global_header__user--is_signed_in .global_header__user__signed_in_message {
  display: inline-block;
}

.global_header__language_container_desktop {
  display: none;
}

.global_header__language {
  height: 44px;
  width: 44px;
  padding: 12px;
}

.global_header__language__icon {
  height: 20px;
  width: 20px;
}

.global_header__language .google_translate_element select {
  height: 40px;
  -webkit-appearance: menulist-button;
}

.global_header__language__select {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 0.4045rem;
}

.global_header__language__select:active,.global_header__language__select:focus,.global_header__language__select:hover {
  background-color: #fff;
}

.global_header__language__select__current_option {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  z-index: auto;
  overflow: hidden;
  position: relative;
  text-shadow: 0.03em 0 rgba(51,40,44,0.2), -0.03em 0 rgba(51,40,44,0.2), 0 0.03em rgba(51,40,44,0.2), 0 -0.03em rgba(51,40,44,0.2), 0.06em 0 rgba(51,40,44,0.2), -0.06em 0 rgba(51,40,44,0.2), 0.09em 0 rgba(51,40,44,0.2), -0.09em 0 rgba(51,40,44,0.2), 0.12em 0 rgba(51,40,44,0.2), -0.12em 0 rgba(51,40,44,0.2), 0.15em 0 rgba(51,40,44,0.2), -0.15em 0 rgba(51,40,44,0.2);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(51,40,44,0.2)), to(rgba(51,40,44,0.2))), -webkit-gradient(linear, left top, left bottom, from(rgba(51,40,44,0.2)), to(rgba(51,40,44,0.2))), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(rgba(51,40,44,0.2), rgba(51,40,44,0.2)), linear-gradient(rgba(51,40,44,0.2), rgba(51,40,44,0.2)), linear-gradient(#fff, #fff);
  background-position: 0% 110%, 100% 110%, 0% 110%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
  color: #fff;
  line-height: 1.4;
  font-size: 0.909090909090909rem;
  font-size: var(--fz--sub);
  color: #fff;
}

.global_header__language__select__current_option:active,.global_header__language__select__current_option:focus,.global_header__language__select__current_option:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(51,40,44,0.2)), to(rgba(51,40,44,0.2))), -webkit-gradient(linear, left top, left bottom, from(rgba(51,40,44,0.2)), to(rgba(51,40,44,0.2))), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(rgba(51,40,44,0.2), rgba(51,40,44,0.2)), linear-gradient(rgba(51,40,44,0.2), rgba(51,40,44,0.2)), linear-gradient(#fff, #fff);
  background-position: 0% 90%, 100% 90%, 0% 90%;
  color: #fff;
}

.global_header__hamburger_menu_btn {
  border: #d6f1ee solid;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(30,64,120,0.102);
  box-shadow: 0px 0px 14px 0px rgba(30,64,120,0.102);
  height: 36px;
  width: 36px;
  margin-left: 8px;
  padding: 8px;
  display: block;
}

.global_header__hamburger_menu_btn__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.global_header__hamburger_menu_drawer {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 63px;
  padding: 16px 12px;
  -webkit-box-shadow: 0px 12px 16px -4px rgba(16,24,40,0.078);
  box-shadow: 0px 12px 16px -4px rgba(16,24,40,0.078);
  background-color: #fff;
  z-index: 1000;
}

.global_header__mobile_navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.global_header__mobile_navigation__anchor {
  padding: 12px;
  color: #1e4078;
  font-size: 1.125rem;
}

.global_header__banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 1394px;
  background-color: #d6f1ee;
  margin-top: -5px;
  padding: 10px;
  padding-top: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  color: #0072bc;
  -webkit-box-shadow: 0px 2px 1px 0px rgba(30,64,120,0.102);
  box-shadow: 0px 2px 1px 0px rgba(30,64,120,0.102);
  -webkit-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
}

.global_header__banner__arrow {
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  color: #0072bc;
}

.global_header__banner:hover {
  background-color: #f3faf9;
  text-decoration: underline;
}

.global_header__banner:hover .global_header__banner__arrow {
  color: #0072bc;
}

.global_header__language {
  position: relative;
  z-index: auto;
}

.google_translate_element,.skiptranslate.goog-te-gadget,.goog-te-gadget-simple {
  overflow: hidden;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.google_translate_element:active ~ .global_header__language__select .global_header__language__select__current_option,.skiptranslate.goog-te-gadget:active ~ .global_header__language__select .global_header__language__select__current_option,.goog-te-gadget-simple:active ~ .global_header__language__select .global_header__language__select__current_option,.google_translate_element:focus ~ .global_header__language__select .global_header__language__select__current_option,.skiptranslate.goog-te-gadget:focus ~ .global_header__language__select .global_header__language__select__current_option,.goog-te-gadget-simple:focus ~ .global_header__language__select .global_header__language__select__current_option,.google_translate_element:hover ~ .global_header__language__select .global_header__language__select__current_option,.skiptranslate.goog-te-gadget:hover ~ .global_header__language__select .global_header__language__select__current_option,.goog-te-gadget-simple:hover ~ .global_header__language__select .global_header__language__select__current_option {
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(200,19%,27%,1)), to(hsla(200,19%,27%,1))), -webkit-gradient(linear, left top, left bottom, from(hsla(200,19%,27%,1)), to(hsla(200,19%,27%,1))), -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(hsla(200,19%,27%,1), hsla(200,19%,27%,1)), linear-gradient(hsla(200,19%,27%,1), hsla(200,19%,27%,1)), linear-gradient(#fff, #fff);
  background-position: 0% 90%, 100% 90%, 0% 90%;
  color: #fff;
}

.hero_section {
  position: relative;
  width: 100%;
  max-width: 1394px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 16px 0 16px;
}

.hero_section__users_count_popup {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #7acbc6;
  padding: 10px;
  border-radius: 14px;
  margin-bottom: 20px;
}

.hero_section__users_count_popup__description a {
  color: #1e4078 !important;
}

.hero_section__users_count_popup__button {
  background-color: #d6f1ee;
  padding: 1px;
  border-radius: 18px;
  min-width: 20px;
  height: 20px;
  margin-left: 10px;
  color: #1e4078;
  cursor: pointer;
}

.hero_section__title {
  color: #072b4a;
  text-align: left;
}

.hero_section__description {
  color: #065286;
  font-size: 1.25rem;
  text-align: left;
  max-width: 60ch;
}

.hero_section__background_image {
  z-index: 0;
  position: absolute;
  right: -5%;
  top: 23%;
  width: 100%;
  min-width: 463px;
  max-width: 935px;
  height: auto;
}

.hero_section__navigation_cards_section {
  z-index: 10;
  margin: 30px 0 40px 0;
  width: 100%;
  max-width: 1240px;
  display: grid;
  grid-template-columns: 1;
  row-gap: 16px;
}

.hero_section__navigation_cards_section__card {
  width: 100%;
  padding: 27px 24px;
  border-radius: 4px;
  color: #fff;
}

.hero_section__navigation_cards_section__card__title {
  color: #fff;
  font-size: 1.5rem;
}

.hero_section__navigation_cards_section__card__content {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 16px;
}

.hero_section__navigation_cards_section__card__anchor {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1e4078;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: #f0f8fe;
  font-size: 1rem;
}

.hero_section__navigation_cards_section__card__anchor__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  fill: none;
  color: #4198f0;
  margin-left: 10px;
}

.join_now {
  width: 100%;
  max-width: 1394px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.join_now__section {
  width: 100%;
  max-width: 1240px;
  background-color: #0072bc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 16px;
  border-radius: 4px;
}

.join_now__section__title {
  color: #fff;
  font-size: 2.25rem;
  text-align: center;
}

.join_now__section__text {
  color: #fff;
  max-width: 900px;
  text-align: center;
  font-size: 1.125rem;
}

.join_now__section__benefits {
  width: 100%;
  max-width: 950px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  margin: 16px 0;
}

.join_now__section__benefits__point {
  color: #fff;
  background-color: #0b456f;
  padding: 8px;
  border-radius: 4px;
  border: 1px #072b4a solid;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(16,24,40,0.102);
  box-shadow: 0px 1px 3px 0px rgba(16,24,40,0.102);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.join_now__section__benefits__point__icon {
  height: 22px;
  width: 20px;
  min-width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 1px;
  margin-right: 8px;
}

.join_now__register_button {
  color: #1e4078;
  background-color: #ade2dd;
  font-size: 1.125rem;
  border-radius: 4px;
  border: 1px #7acbc6 solid;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 16px 0;
  padding: 16px 28px;
}

.join_now__register_button:hover {
  background-color: #7acbc6;
}

.join_now__register_button__icon {
  height: 24px;
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.join_now__register_button__icon svg {
  width: 24px;
  height: 24px;
  color: #1e4078;
  margin-left: 16px;
}

.knowledge_hubs {
  width: 100%;
  max-width: 1394px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  padding: 40px 16px;
  z-index: 10;
}

.knowledge_hubs__title {
  color: #072b4a;
  margin-bottom: 16px;
}

.knowledge_hubs__content {
  width: 100%;
  max-width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.knowledge_hubs__content__column {
  width: 100%;
}

.knowledge_hubs__content__hub {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px #c1e2fc solid;
  font-size: 1.5rem;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  cursor: pointer;
}

.knowledge_hubs__content__hub:hover {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(16,24,40,0.2);
  box-shadow: 0px 3px 5px 0px rgba(16,24,40,0.2);
}

.knowledge_hubs__content__hub[open] .knowledge_hubs__content__hub__icon {
  background-color: #b9e2fe;
}

.knowledge_hubs__content__hub[open] .knowledge_hubs__content__hub__icon__expand {
  display: none;
}

.knowledge_hubs__content__hub[open] .knowledge_hubs__content__hub__icon__minimise {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.knowledge_hubs__content__hub summary {
  padding: 16px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.knowledge_hubs__content__hub__content {
  padding: 0 16px;
}

.knowledge_hubs__content__hub__text {
  color: #072b4a;
  font-size: 1.125rem;
  font-weight: semibold;
}

.knowledge_hubs__content__hub__icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #f0f8ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
}

.knowledge_hubs__content__hub__icon__minimise {
  display: none;
}

.knowledge_hubs__content__hub__sub_anchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #072b4a;
  font-size: 1rem;
  margin: 12px 0;
}

.knowledge_hubs__content__hub__sub_anchor__further_arrow {
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-left: 12px;
  color: #0072bc;
}

.modal_information {
  position: relative;
  z-index: auto;
}

.modal_information__close_button {
  position: absolute;
  top: -3.236rem;
  right: 0;
  height: 2.427rem;
  width: 2.427rem;
  border-radius: 50%;
  background-color: hsla(201,21%,11%,1);
  padding: 0.1618rem;
}

.modal_information__close_button:active,.modal_information__close_button:focus,.modal_information__close_button:hover {
  -webkit-animation: card-icon-hover 0.22s ease-in-out;
  animation: card-icon-hover 0.22s ease-in-out;
}

.modal_information__close_button__icon__path {
  fill: #fff;
}

.modal_information__aside {
  z-index: 100;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: hsla(200,9%,33%,0.4);
}

.aside_info_is_open .modal_information__aside {
  -webkit-animation: fade-in 0.14s ease-in-out;
  animation: fade-in 0.14s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal_information__item {
  cursor: auto;
  -webkit-transform: translateY(60%);
  transform: translateY(60%);
  display: none;
  width: 32.36rem;
  max-width: 92vw;
  border-radius: 3px;
  padding: 2.156794rem 1.618rem 1.618rem;
}

.modal_information__item--knowledge_sharing {
  border: 1px solid hsla(199,38%,13%,1);
  border-top: 4px solid hsla(199,38%,13%,1);
  background-color: #1b3846;
}

.modal_information__item--capacity_process_improvement {
  border: 1px solid hsla(92,4%,26%,1);
  border-top: 4px solid hsla(92,4%,26%,1);
  background-color: #515a49;
}

.aside_info_is_open .modal_information__item {
  -webkit-animation: modal-info-slide-upwards 0.14s ease-in-out;
  animation: modal-info-slide-upwards 0.14s ease-in-out;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.modal_information__item__title,.modal_information__item__description {
  text-shadow: 0 0 0.1em hsla(200,29%,21%,0.8);
  color: #fff;
}

.modal_information__item__title {
  margin-top: -0.539333333333333rem;
  font-size: 1.21rem;
  font-size: var(--fz--h3);
}

* + .modal_information__item__description {
  margin-top: 1.077588rem;
}

h2 + .modal_information__item__description {
  margin-top: 0.809rem;
}

.modal_navigation {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  cursor: auto;
  overflow-x: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  width: 100%;
}

.modal_navigation--knowledge_sharing {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  left: 0;
  -webkit-box-shadow: 2px 0 0.8em hsla(199,38%,13%,1);
  box-shadow: 2px 0 0.8em hsla(199,38%,13%,1);
  background-color: #1b3846;
}

.aside_nav_is_open .modal_navigation--knowledge_sharing {
  -webkit-animation: modal-nav-slide-to-right 0.14s ease-in-out;
  animation: modal-nav-slide-to-right 0.14s ease-in-out;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.modal_navigation--capacity_process_improvement {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  right: 0;
  -webkit-box-shadow: -2px 0 0.8em hsla(199,38%,13%,1);
  box-shadow: -2px 0 0.8em hsla(199,38%,13%,1);
  background-color: #515a49;
}

.aside_nav_is_open .modal_navigation--capacity_process_improvement {
  -webkit-animation: modal-nav-slide-to-left 0.14s ease-in-out;
  animation: modal-nav-slide-to-left 0.14s ease-in-out;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.modal_navigation__close_button {
  position: absolute;
  top: 0.809rem;
  right: 0.809rem;
  height: 2.427rem;
  width: 2.427rem;
  border-radius: 50%;
  background-color: hsla(201,21%,11%,1);
  padding: 0.1618rem;
}

.modal_navigation__close_button:active,.modal_navigation__close_button:focus,.modal_navigation__close_button:hover {
  -webkit-animation: card-icon-hover 0.22s ease-in-out;
  animation: card-icon-hover 0.22s ease-in-out;
}

.modal_navigation__close_button__icon__path {
  fill: #fff;
}

.modal_navigation__aside {
  z-index: 100;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: hsla(200,9%,33%,0.4);
}

.aside_nav_is_open .modal_navigation__aside {
  -webkit-animation: fade-in 0.14s ease-in-out;
  animation: fade-in 0.14s ease-in-out;
  display: block;
}

.modal_navigation__title {
  padding: 3.236rem 1.618rem 0.809rem;
  text-shadow: 0 0 0.1em #172b35;
  font-size: 1.1rem;
  font-size: var(--fz--h4);
  color: #fff;
}

.modal_navigation__list,.modal_navigation__sub_list {
  width: 100%;
  max-width: 100%;
}

.modal_navigation__list {
  padding-bottom: 3.236rem;
}

.modal_navigation__sub_list {
  display: none;
  width: 100%;
  max-width: 100%;
}

.modal_navigation__sub_list--is_visible {
  display: block;
}

.modal_navigation__anchor,.modal_navigation__sub_list_button,.modal_navigation__sub_anchor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0.809rem 1.618rem;
  text-shadow: 0 0 0.1em #172b35;
  text-align: left;
}

.modal_navigation--knowledge_sharing .modal_navigation__anchor:active,.modal_navigation--knowledge_sharing .modal_navigation__sub_list_button:active,.modal_navigation--knowledge_sharing .modal_navigation__sub_anchor:active,.modal_navigation--knowledge_sharing .modal_navigation__anchor:focus,.modal_navigation--knowledge_sharing .modal_navigation__sub_list_button:focus,.modal_navigation--knowledge_sharing .modal_navigation__sub_anchor:focus,.modal_navigation--knowledge_sharing .modal_navigation__anchor:hover,.modal_navigation--knowledge_sharing .modal_navigation__sub_list_button:hover,.modal_navigation--knowledge_sharing .modal_navigation__sub_anchor:hover {
  background-color: hsla(199,38%,13%,1);
}

.modal_navigation--capacity_process_improvement .modal_navigation__anchor:active,.modal_navigation--capacity_process_improvement .modal_navigation__sub_list_button:active,.modal_navigation--capacity_process_improvement .modal_navigation__sub_anchor:active,.modal_navigation--capacity_process_improvement .modal_navigation__anchor:focus,.modal_navigation--capacity_process_improvement .modal_navigation__sub_list_button:focus,.modal_navigation--capacity_process_improvement .modal_navigation__sub_anchor:focus,.modal_navigation--capacity_process_improvement .modal_navigation__anchor:hover,.modal_navigation--capacity_process_improvement .modal_navigation__sub_list_button:hover,.modal_navigation--capacity_process_improvement .modal_navigation__sub_anchor:hover {
  background-color: hsla(92,4%,26%,1);
}

.modal_navigation__anchor__text,.modal_navigation__sub_list_button__text,.modal_navigation__sub_anchor__text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: 0.1618rem;
  margin-bottom: -0.1618rem;
  font-size: 1.1rem;
  font-size: var(--fz--h4);
  color: #fff;
}

.modal_navigation__anchor__further_arrow,.modal_navigation__sub_list_button__further_arrow,.modal_navigation__sub_anchor__further_arrow,.modal_navigation__anchor__open_icon,.modal_navigation__sub_list_button__open_icon,.modal_navigation__sub_anchor__open_icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 1.077588rem;
  width: 1.077588rem;
  margin-left: 1.618rem;
}

.modal_navigation__anchor__further_arrow__icon__path,.modal_navigation__sub_list_button__further_arrow__icon__path,.modal_navigation__sub_anchor__further_arrow__icon__path {
  fill: hsla(201,54%,54%,1);
}

.modal_navigation__anchor__open_icon__icon__path,.modal_navigation__sub_list_button__open_icon__icon__path,.modal_navigation__sub_anchor__open_icon__icon__path {
  fill: hsla(201,54%,74%,1);
}

.modal_navigation__anchor__hyperlink_arrow__icon__path,.modal_navigation__sub_list_button__hyperlink_arrow__icon__path,.modal_navigation__sub_anchor__hyperlink_arrow__icon__path {
  fill: hsla(201,54%,74%,1);
}

.modal_navigation__sub_list_button__open_icon {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.modal_navigation__sub_list_button--list_is_visible .modal_navigation__sub_list_button__open_icon {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.modal_navigation__sub_anchor {
  padding-left: 2.8315rem;
}

.navigation_cards {
  display: block;
  height: 100%;
  width: 100%;
  margin-top: auto;
  padding: 0 0 1.618rem;
}

.navigation_cards__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navigation_cards__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.navigation_cards__card {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  position: relative;
  z-index: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 100%;
  border-radius: 3px;
  padding: 0.809rem;
  text-align: left;
}

.navigation_cards__card--knowledge_sharing {
  background-color: #1b3846;
}

.navigation_cards__card--capacity_process_improvement {
  background-color: #515a49;
}

.navigation_cards__title {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  text-shadow: 0 0 0.1em hsla(200,29%,21%,0.8);
  color: #fff;
}

.navigation_cards__teaser {
  margin-bottom: auto;
  text-shadow: 0 0 0.1em hsla(200,29%,21%,0.8);
  font-size: 1.1rem;
  font-size: var(--fz--h4);
  color: #fff;
}

* + .navigation_cards__teaser {
  margin-top: 0.4045rem;
}

.navigation_cards__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

* + .navigation_cards__buttons {
  margin-top: 1.077588rem;
}

.navigation_cards__button {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 49%;
  flex: 0 1 49%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 1px 1px rgba(23,43,53,0.1);
  box-shadow: 0 1px 1px rgba(23,43,53,0.1);
  border-radius: 3px;
  background-color: #f6f5f3;
  padding: 0.539333333333333rem 1.618rem;
}

.navigation_cards__button:active,.navigation_cards__button:focus,.navigation_cards__button:hover {
  -webkit-box-shadow: 0 3px 6px rgba(23,43,53,0.1);
  box-shadow: 0 3px 6px rgba(23,43,53,0.1);
}

* + .navigation_cards__button {
  margin-left: 0.809rem;
}

.navigation_cards__button:active .navigation_cards__button__icon,.navigation_cards__button:focus .navigation_cards__button__icon,.navigation_cards__button:hover .navigation_cards__button__icon {
  -webkit-animation: card-icon-hover 0.22s ease-in-out;
  animation: card-icon-hover 0.22s ease-in-out;
}

.navigation_cards__button__text,.navigation_cards__button__icon {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.navigation_cards__button__text {
  font-size: 1.1rem;
  font-size: var(--fz--h4);
}

.navigation_cards__card--knowledge_sharing .navigation_cards__button__text {
  color: hsla(199,38%,13%,1);
}

.navigation_cards__card--capacity_process_improvement .navigation_cards__button__text {
  color: hsla(92,4%,26%,1);
}

.navigation_cards__button__icon {
  -webkit-transition: all 0.14s ease-in-out;
  transition: all 0.14s ease-in-out;
  margin-top: -0.20225rem;
}

* + .navigation_cards__button__icon {
  margin-left: 0.809rem;
}

.navigation_cards__button__list_icon,.navigation_cards__button__question_mark_icon {
  display: block;
  height: 1.2135rem;
  width: 1.2135rem;
}

.navigation_cards__card--knowledge_sharing .navigation_cards__button__list_icon__path,.navigation_cards__card--knowledge_sharing .navigation_cards__button__question_mark_icon__path {
  fill: #1b3846;
}

.navigation_cards__card--capacity_process_improvement .navigation_cards__button__list_icon__path,.navigation_cards__card--capacity_process_improvement .navigation_cards__button__question_mark_icon__path {
  fill: #515a49;
}

.regional_hubs {
  width: 100%;
  max-width: 1394px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  padding: 0px 16px;
  z-index: 10;
}

.regional_hubs__title {
  color: #072b4a;
  margin-bottom: 16px;
}

.regional_hubs__content {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
}

.regional_hubs__content__hub {
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  border: 1px #c1e2fc solid;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.regional_hubs__content__hub__text {
  color: #072b4a;
}

.regional_hubs__content__hub__icon {
  width: 40px;
  height: 40px;
}

.resources {
  width: 100%;
  max-width: 1394px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 16px 48px 16px;
}

.resources__title {
  color: #072b4a;
}

.resources__cards_section {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1;
  row-gap: 16px;
  margin-top: 16px;
}

.resources__cards_section__card {
  color: #fff;
  background-color: #255f60;
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.051);
  padding: 24px;
}

.resources__cards_section__card:hover {
  background-color: #204041;
  -webkit-box-shadow: 0px 7px 10px 0px rgba(16,24,40,0.239);
  box-shadow: 0px 7px 10px 0px rgba(16,24,40,0.239);
}

.resources__cards_section__card:hover .resources__cards_section__card__icon {
  border: 8px #ade2dd solid;
}

.resources__cards_section__card__icon {
  width: 55px;
  height: 55px;
  background-color: #224b4d;
  border: 8px #fff solid;
  border-radius: 28px;
  padding: 8px;
}

.resources__cards_section__card__title {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #fff;
}

.resources__cards_section__card__text {
  font-size: 1rem;
  color: #fff;
}

.resources__cards_section__card__button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  background-color: #204041;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.resources__cards_section__card__button__icon {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 8px;
}

.resources__cards_section__card__button__icon svg {
  width: 20px;
  height: 20px;
  color: #fff;
  margin-left: 16px;
}

@font-face {
  font-family:'relative--book';src:url("../fonts/relative--book/relative--book.eot");src:url("../fonts/relative--book/relative--book.eot?#iefix") format('embedded-opentype'), url("../fonts/relative--book/relative--book.woff") format('woff'), url("../fonts/relative--book/relative--book.ttf") format('truetype');font-weight:400;font-style:normal;font-stretch:normal;
}

@font-face {
  font-family:'relative--medium';src:url("../fonts/relative--medium/relative--medium.eot");src:url("../fonts/relative--medium/relative--medium.eot?#iefix") format('embedded-opentype'), url("../fonts/relative--medium/relative--medium.woff") format('woff'), url("../fonts/relative--medium/relative--medium.ttf") format('truetype');font-weight:400;font-style:normal;font-stretch:normal;
}

@media only screen and (min-width: 30.0625em) {
  .global_header__search__form__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .global_header__search__form__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .global_header__search__form__inner {
    height: auto;
  }

  .global_header__search__form__text_input {
    width: 19.416rem;
  }

  .global_header__user {
    height: 44px;
  }

  .global_header__user__anchor {
    padding: 0 18px;
  }

  .global_header__user__anchor__sign_in {
    margin-right: 16px;
  }

  .global_header__hamburger_menu_btn {
    margin-left: 16px;
  }

  .global_header__hamburger_menu_btn {
    height: 44px;
    width: 44px;
    padding: 10px;
  }

  .hero_section {
    padding: 48px 16px 0 16px;
  }

  .hero_section__title {
    text-align: center;
  }

  .hero_section__description {
    text-align: center;
  }

  .modal_navigation {
    width: 75vw;
  }
}

@media only screen and (min-width: 42.5625em) {
  :root {
    --ratio: 1.15;
  }

  h1 {
    font-size: 1.749006249999999rem;
    font-size: var(--fz--h1);
  }

  h2 {
    font-size: 1.520875rem;
    font-size: var(--fz--h2);
  }

  * + h2 {
    margin-top: 2.281312499999999rem;
    margin-top: var(--sp--h2);
  }

  * + h3 {
    margin-top: 1.98375rem;
    margin-top: var(--sp--h3);
  }

  * + h4 {
    margin-top: 1.725rem;
    margin-top: var(--sp--h4);
  }

  sup {
    font-size: 0.869565217391304rem;
    font-size: var(--fz--sub);
  }

  .global_footer__navigation__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .global_footer__navigation__item {
    margin: 0 16px;
  }

  .global_header__language__select__current_option {
    font-size: 0.869565217391304rem;
    font-size: var(--fz--sub);
  }

  .hero_section__background_image {
    left: 50%;
    top: 20%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .hero_section__navigation_cards_section {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 29px;
    column-gap: 29px;
  }

  .join_now {
    padding: 48px 16px 80px 16px;
  }

  .join_now__section {
    padding: 40px;
  }

  .join_now__section__benefits {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 8px;
    column-gap: 8px;
  }

  .knowledge_hubs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .knowledge_hubs__title {
    margin-bottom: 32px;
  }

  .knowledge_hubs__content__column {
    width: 48.5%;
  }

  .knowledge_hubs__content__hub {
    margin-bottom: 16px;
  }

  .modal_information__item__title {
    font-size: 1.3225rem;
    font-size: var(--fz--h3);
  }

  .modal_navigation {
    width: 50vw;
  }

  .modal_navigation__title {
    font-size: 1.15rem;
    font-size: var(--fz--h4);
  }

  .modal_navigation__anchor__text,.modal_navigation__sub_list_button__text,.modal_navigation__sub_anchor__text {
    font-size: 1.15rem;
    font-size: var(--fz--h4);
  }

  .navigation_cards__list {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .navigation_cards__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
  }

  * + .navigation_cards__item:nth-of-type(n+3) {
    margin-top: 2%;
  }

  .navigation_cards__teaser {
    padding-right: 1.886588rem;
  }

  .navigation_cards__teaser {
    font-size: 1.15rem;
    font-size: var(--fz--h4);
  }

  .navigation_cards__button__text {
    font-size: 1.15rem;
    font-size: var(--fz--h4);
  }

  .regional_hubs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .regional_hubs__title {
    margin-bottom: 32px;
  }

  .regional_hubs__content {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 24px;
    column-gap: 24px;
  }

  .resources {
    padding: 48px 16px 70px 16px;
  }

  .resources__cards_section {
    grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 24px;
    column-gap: 24px;
  }

  .resources__cards_section {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 55.0625em) {
  .hero_section__navigation_cards_section {
    margin: 65px 0 80px 0;
  }

  .knowledge_hubs {
    padding: 80px 16px;
  }
}

@media only screen and (min-width: 67.5625em) {
  .global_footer__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .global_footer__inner__main_section {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .global_footer__copyright {
    text-align: center;
    margin-top: 0;
  }

  .global_header {
    position: fixed;
    z-index: 1000;
  }

  .global_header__global_content__inner {
    padding: 12px 8px;
  }

  .global_header__banner__arrow {
    color: rgba(0,0,0,0);
  }

  .hero_section {
    padding: 175px 16px 0 16px;
  }

  .hero_section__background_image {
    top: 40%;
  }

  .hero_section__navigation_cards_section {
    grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 39px;
    column-gap: 39px;
  }

  .modal_navigation {
    width: 33.333vw;
  }

  .navigation_cards__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 44%;
    flex: 0 0 44%;
  }

  .resources__cards_section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 71.9375em) {
  .global_header {
    padding: 16px 0;
  }

  .global_header__navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .global_header__language_container_desktop {
    display: block;
  }

  .global_header__language {
    margin-left: 16px;
  }

  .global_header__hamburger_menu_btn {
    display: none;
  }

  .global_header__hamburger_menu_drawer {
    display: none;
  }
}

@media only screen and (min-width: 80.0625em) {
  :root {
    --ratio: 1.2;
  }

  h1 {
    font-size: 2.0736rem;
    font-size: var(--fz--h1);
  }

  h2 {
    font-size: 1.728rem;
    font-size: var(--fz--h2);
  }

  * + h2 {
    margin-top: 2.592rem;
    margin-top: var(--sp--h2);
  }

  * + h3 {
    margin-top: 2.16rem;
    margin-top: var(--sp--h3);
  }

  * + h4 {
    margin-top: 1.8rem;
    margin-top: var(--sp--h4);
  }

  sup {
    font-size: 0.833333333333333rem;
    font-size: var(--fz--sub);
  }

  .global_header__language__select__current_option {
    font-size: 0.833333333333333rem;
    font-size: var(--fz--sub);
  }

  .modal_information__item__title {
    font-size: 1.44rem;
    font-size: var(--fz--h3);
  }

  .modal_navigation__title {
    font-size: 1.2rem;
    font-size: var(--fz--h4);
  }

  .modal_navigation__anchor__text,.modal_navigation__sub_list_button__text,.modal_navigation__sub_anchor__text {
    font-size: 1.2rem;
    font-size: var(--fz--h4);
  }

  .navigation_cards__teaser {
    font-size: 1.2rem;
    font-size: var(--fz--h4);
  }

  .navigation_cards__button__text {
    font-size: 1.2rem;
    font-size: var(--fz--h4);
  }
}

@media only screen and (min-width: 105.0625em) {
  .modal_navigation {
    width: 25vw;
  }
}

@media (min-width: 1150px) and (max-width: 1190px) {
  .global_header__navigation__anchor {
    padding: 12px 11px;
  }
}

@media only screen and (max-width: 42.5em) {
  .navigation_cards__item {
    max-width: 35.596000000000004rem;
  }

  .navigation_cards__item + .navigation_cards__item {
    margin-top: 2vw;
  }
}

@media only screen and (max-width: 30em) {
  .global_header__search__form__inner > * + * {
    margin-top: 0.809rem;
  }

  .global_header__search__form__close_button {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-with-delay {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-with-delay {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes card-icon-hover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-0.1618rem);
    transform: translateY(-0.1618rem);
  }

  85% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes card-icon-hover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-0.1618rem);
    transform: translateY(-0.1618rem);
  }

  85% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes modal-nav-slide-to-left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes modal-nav-slide-to-left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes modal-nav-slide-to-right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes modal-nav-slide-to-right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes modal-info-slide-upwards {
  0% {
    -webkit-transform: translateY(60%);
    transform: translateY(60%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes modal-info-slide-upwards {
  0% {
    -webkit-transform: translateY(60%);
    transform: translateY(60%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes header-strapline-slide-upwards {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes header-strapline-slide-upwards {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes search-modal-slide-down {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes search-modal-slide-down {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
